:root {
    --theme-color: #49a2e7;
    --light-bg:#fff;
    --theme-inner-bg: #f5f5f5;
    --dark-text:#191919;
    --slick-bg:#4a4a4a;
    --main-text-color:#6A6A6A;
    --grey:#f4f4f4;
    --button-bg:#2196F3;
}

@font-face {
    font-family: "Lato-Light";
    src: url("../fonts/Lato-Light.woff");
}

@font-face {
    font-family: "Lato-Regular";
    src: url("../fonts/Lato-Regular.woff");
}

@font-face {
    font-family: "Lato-Medium";
    src: url("../fonts/Lato-Medium.woff");
}

@font-face {
    font-family: "Lato-Bold";
    src: url("../fonts/Lato-Bold.woff");
}

html,
body,
#root {
    height: 100%;
}

body {
    font-size: 16px;
    font-family: "Lato-Regular";
    color:var(--main-text-color)
}
p{
    font-size: 15px;
    line-height: 1.6em;
}
a{
    text-decoration: none;
}
header .nav-link{
    color:var(--light-bg);
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
}
header .nav-link:hover{
    color:var(--theme-color)
}
.main-wrapper{
    z-index: 10;
    position: relative;
}
.header{    
    border-top: 5px solid var(--theme-color);
}
.header-solid {
    background: var(--dark-text);
    box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.2);
}
.banner-form{
    max-width: 52rem;
    margin: 0 auto;
    height: 800px;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    z-index: 2;
    position: relative;
    color:var(--light-bg)
}
.btn-outline-white {
    border: 2px solid var(--light-bg);
    color: var(--light-bg);
    border-radius: 50px;
}
.btn-outline-primary {
    border: 2px solid var(--theme-color);
    color: var(--theme-color);
    border-radius: 50px;
}
.btn-outline-primary:hover {
    background: var(--theme-color);
    color: var(--light-bg) !important;
}
.banner{
    background-image: url('../images/banner3.png');
    width:100%;
}
.banner1{
    background-image: url('../images/banner4.png');
}
.banner2{
    background-image: url('../images/banner8.png');
}
.sub-text{
font-size: 24px;
text-transform: uppercase;
}
.overlay::before{
    position: absolute;
    content: "";
    z-index: 3;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("../images/overaly.png");
    z-index: -1;
}
.banner .overlay {
    z-index: 1;
    position: relative;
}
.slick-slide{
    height: 750px;
}
.slick-next{
    right:0px !important
}
.slick-prev{
    left:0px
}
.button-group{
    display: flex;
    gap:20px;
    align-items: center;
    justify-content: center;
}
.main-head{
    text-transform: uppercase;
    font-size: 48px;
}
.button-group .btn{
    /* width: 130px; */
    text-transform: uppercase;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
}
.btn-outline-white:hover{
    background: var(--light-bg);
}
.dropdown-parent{
    position: relative;
}
.dropdown-submenu{
    position: absolute;
    background-color: var(--light-bg);
    /* display: none; */
    padding: 0 15px;
    border: 0;
    border-radius: 0px;
    width: 100%;
    box-shadow: none;
    
    min-width: 180px;
    top: 100%;
    left: -10px;
    border-radius: 0;
    display: block;
    visibility: hidden;
    transition: 0.3s ease;
    opacity: 0;
    transform: scale(0.8);
}
header .dropdown-submenu li a{
    color:var(--dark-text);
}

.dropdown-parent .nav-link::after{
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.dropdown-parent .nav-link:hover{
  color:var(--theme-color)
}
.dropdown-parent.nav-item:hover .dropdown-submenu{
    visibility: visible;
    opacity: 1;
    transform: scaleX(1);
  }
.dropdown-submenu .nav-item .nav-link::after{
   border:none;
}
.dropdown-submenu .nav-link{
    text-transform: capitalize;
 display: flex;
 font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    padding: 15px;
    color: #333;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: 0.2s ease;
}
.slick-dots{
    bottom: 65px !important;
}
.slick-next,.slick-prev{
display: none !important;
}
.slick-active .react-slick-dots{
    background: var(--dark-text);
    color:var(--light-bg)
}
.react-slick-dots{
    background: var(--slick-bg);
    color:var(--light-bg)
}
.slick-dots li{
    width:80px !important;
}


/* services */
.services-sec{
    display: flex;
    padding-bottom: 3rem;
}
.services{
    padding: 80px 0 0 0;
    position: relative;
}
.services::before{
    background: var(--light-bg);
    content: "";
    height: 75px;
    top: -37px;
    position: absolute;
    width: 100%;
    z-index: 2;
    transform: skew(0deg, 2deg);
}
.title-icon {
    font-size: 32px;
    margin-right: 15px;
    color: #959595;
}
.title {
    position: relative;
    margin: 0;
    text-transform: uppercase;
    line-height: 32px;
    border-left: 2px solid #e7e7e7;
    display: inline-block;
    padding-left: 15px;
    transition: all 0.3s ease 0s;
    color:var(--dark-text)
}
.title-desc {
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    text-transform: capitalize;
    display: block;
    color: #6A6A6A;
}
.feature{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.services-head{
    font-size: 16px;
    text-transform: uppercase;
    color:var(--dark-text);
}

h2.services-title{
    text-transform: uppercase;
    font-size: 18px;
}
.react-tabs__tab-list{
    border-bottom: unset !important;
}
.react-tabs__tab--selected{
background: var(--dark-text) !important;
color:var(--light-bg) !important;
border-radius: 0px !important;
}
.portfolio{
    background: var(--light-bg);
    padding: 80px 0 0 0;
}
.react-tabs__tab{
    padding: 5px 15px 5px !important;
    border: 1px solid #ddd !important ;
    transition: all 0.3s;
    margin-right: 10px;
    font-size: 15px;
    margin-bottom: 10px;
}
.react-tabs__tab:hover{
    background: var(--dark-text) !important;
    color: #fff;
}
.facts {
    position: relative;
    padding: 46px 40px 30px;
    transition: all 300ms ease;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.facts-num .counter {
    font-size: 42px;
    font-weight: 700;
    margin-top: 20px;
    display: inline-block;
    color:var(--light-bg)
}
.facts h3 {
    font-size: 18px;
    margin-top: 8px;
    color: var(--light-bg);
    text-transform: uppercase;
}
.facts.one {
    background: rgba(0, 0, 0, 0.3);
}
.facts-wrapper {
    background: #2a2d32;
    color: var(--light-bg);
}
.facts.two {
    background: rgba(0, 0, 0, 0.2);
}
.facts.three {
    background: rgba(0, 0, 0, 0.15);
}
.facts.four {
    background: rgba(0, 0, 0, 0.09);
}
.facts:hover{
background: var(--dark-text);
}
.portfolio-img:hover .overlay-portfolio{
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0px;
    width:100%;
    height:100%;
    display: block;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}
.portfolio-img{
    height:100%;
}
.overlay-portfolio{
    display: none;
}
.portfolio-img{
    position: relative;
}
.portfolio-img:hover .overlay-portfolio::after{
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    content: "";
}
.overlay-links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    z-index: 9;
}
.overlay-portfolio-head{
    font-size: 18px;
    text-transform: uppercase;
    color: var(--light-bg);
    margin: 0% 0 10px 0;
    transition: transform 0.35s;
}
.overlay-links .links-control{
    color:var(--light-bg);
}
.float-right{
    float: right;
}

.about-us{
    background: #f4f4f4;
}
.img-block-head h2 {
    font-size: 18px;
    line-height: normal;
    margin: 5px 0 0 0;
    text-transform: uppercase;
}
.img-block-head h3 {
    font-size: 40px;
    line-height: 100%;
    text-transform: uppercase;
}
.img-block-right {
    padding: 46px 73px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 0;
}

.image-block-content .feature-icon {
    width: 60px;
    height: 60px;
    line-height: 60px;
    color: #6A6A6A;
    border-radius: 100%;
    display: inline-block;
    text-align: center;
    background: var(--theme-color);
}
.image-block-content{
    display:flex;
    gap: 25px;
    align-items: center;
    margin-bottom: 23px;
}
.feature-content{
    width: calc(100% - 85px);
    padding-right: 20px;
    margin-top: 1rem;
}
.feature-content h3{
    font-size: 18px;
    margin-bottom: 0;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #323232;
    font-family: "Lato-Regular";
}
.feature-content p{
    font-weight: 400;
    font-size: 15px;
    line-height: 1.6em;
}
.gap-30{
    height: 30px;
}
.our-team{
    padding: 80px 0;
}
.img-hexagon {
    width: 240px;
    height: 250px;
    position: relative;
    display: inline-block;
}
.img-hexagon img {
    width: 100%;
    height: 100%;
}
.img-hexagon span {
    position: absolute;
    display: block;
    float: left;
    border-left: 121px solid #fff;
    border-right: 121px solid #fff;
}
.img-top {
    top: -1px;
    left: -1px;
    border-top: 0px solid transparent;
    border-bottom: 75px solid transparent;
}
.img-bottom {
    bottom: -1px;
    left: -1px;
    border-bottom: 0px solid transparent;
    border-top: 75px solid transparent;
}
.team-content h3 {
    margin-bottom: 0;
    font-size: 20px;
    color: #323232;
}
.team-content p {
    color: #959595;
    font-size: 15px;
}
.team-social a {
    background: #d5d5d5;
    color: #fff;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 100%;
    display: inline-block;
    text-align: center;
    transition: all 300ms ease;
    margin-right: 3px;
}
.team-social a.fb:hover {
    background: #325c94;
}
.team-social a.twt:hover {
    background: #00abdc;
}
.team-social a.gplus:hover {
    background: #dd4b39;
}
.team-social a.linkdin:hover {
    background: #007bb6;
}
.team-social a.dribble:hover {
    background: #ea4c89;
}
.parallax1 {
    background: url(../images/parallax1.jpg) no-repeat;
}
.parallax {
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-attachment: fixed !important;
    padding: 110px 0;
    color: #fff;
    position: relative;
}
.parallax-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../images/overaly.png);
}
.parallax h2 {
    color: #fff;
    margin-top: 0;
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}
.parallax h3 {
    margin-bottom: 30px;
    color: #fff;
    margin-top: 0;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}
.btn {
    border-radius: 50px;
    padding: 10px 30px;
    background: none;
    font-size: 14px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    border-width: 2px;
}
.btn-outline-white {
    border: 2px solid #fff;
    color: #fff;
}
.pricing {
    padding: 80px 0;
}
.plan {
    border: 1px solid #dedede;
}
.plan-name {
    display: block;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    padding: 20px 0;
    color: #959595;
}
.plan-name small {
    display: block;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
}
.plan-price {
    font-size: 48px;
    padding: 30px 0;
    margin-bottom: 30px;
    position: relative;
    background: #f2f2f2;
    transition: 0.3s;
}
.plan-price .currency {
    top: -0.9em;
    font-size: 50%;
    left: -0.01em;
    font-weight: 700;
}
.plan-price sub {
    font-size: 18px;
    font-weight: 700;
}
.plan-price:after {
    bottom: -30px;
    border: solid transparent;
    content: " ";
    position: absolute;
    border-width: 15px;
    border-top-color: #f2f2f2;
    left: 50%;
    margin-left: -16px;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
}
.plan ul {
    background: #fff;
    padding: 0;
    margin-bottom: 0;
}
.plan ul li {
    border-top: 1px dashed #dedede;
    padding: 12px 29px;
    font-weight: 500;
}
.plan ul li:first-child {
    border-top: 0 !important;
}
.plan a.btn {
    background: #fff;
    margin-top: 25px;
    margin-bottom: -20px;
    padding: 8px 30px;
}
.plan:hover .btn-outline-primary {
    background-color: var(--theme-color);
    color: #fff !important;
}
.plan.featured .plan-price, .plan:hover .plan-price {
    background: var(--theme-color);
    color: #fff !important;
}
.parallax2 {
    background: url(../images/parallax2.jpg) no-repeat;
}
.testimonial-thumb img {
    border: 1px solid #8E8E8E;
    padding: 3px;
    width: 80px;
    height: 80px;
    border-radius: 100%;
}
.testimonial-content .testimonial-text {
    font-size: 16px;
    line-height: 24px;
    max-width: 800px;
    padding-top: 30px;
    display: inline-block;
    position: relative;
    color: #fff;
}
.testimonial-content h3.name {
    font-size: 18px;
    color: #fff;
    margin: 10px 0;
    line-height: 2;
}
.testimonial-content h3 span {
    display: block;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    margin-top: -10px;
}
.clients{
    padding: 80px 0;
}
.client_logo a {
    margin-right: 20px;
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    display: inline-block;
    padding: 10px;
    transition: all 350ms;
}
.client_logo a:hover {
    border: 1px solid var(--theme-color);
}
.footer {
    padding: 60px 0 100px;
    background: #2e2e2e url(../images/footer-bg.jpg) no-repeat center 0;
    background-size: cover;
    color: #fff;
    font-size: 14px;
}
.widget-title {
    margin-bottom: 25px;
    font-size: 20px;
}
.footer .widget-title {
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}
.footer-widget .latest-post-content h4 a {
    color: #fff;
    text-decoration: none;
}
.footer-widget .latest-post-content h4 a:hover {
    color: var(--theme-color);
    text-decoration: none;
}
.latest-post-items {
    padding-bottom: 10px;
}
.latest-post-content h4 {
    font-size: 14px;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 0.5rem;
}
.post-meta span {
    padding-right: 10px;
}
.post-meta {
    color: #959595;
    font-size: 13px;
    margin-bottom: 1rem;
}
.footer-widget .post-meta, .footer-widget .post-meta a {
    color: #fff;
    text-decoration: none;
}

.footer-widget .img-gallery {
    margin-top: 5px;
    display: inline-block;
}
.img-gallery a.thumb-holder {
    overflow: hidden;
    position: relative;
}
.img-gallery a.thumb-holder img {
    margin: 0 15px 15px 0;
    display: inline-block;
    width: 95px;
    height: 85px;
    transition: opacity 0.35s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    cursor:pointer;
}
.footer-about-us h4 {
    font-size: 16px;
    margin-bottom: 0;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}
.footer-about-us h4 {
    color: var(--theme-color);
}
.subscribe {
    margin-top: 20px;
}
.footer .subscribe {
    position: relative;
}
.subscribe input.form-control {
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: none;
    padding: 6px 20px;
    height: 50px;
    background: none;
    color: #959595;
    font-size: 14px;
    border-radius: 0;
}
.subscribe button svg {
    font-size: 20px;
}
.subscribe .input-group-addon {
    background: none;
    border-radius: 0;
    border: 0;
    padding: 0;
    position: absolute;
    right: 0;
}
.subscribe button {
    padding: 13px 20px;
    color: #fff;
}
.copyright {
    padding: 30px 0;
    background: #191919;
    color: #fff;
    position: relative;
}
.copyright::before{
    content: "";
    position: absolute;
    background: #191919;
    height: 50px;
    top: -25px;
    transform: skew(0deg, -2deg);
    width: 100%;
    z-index: -1;
}
.icon-pentagon {
    display: inline-block;
    height: 24px;
    width: 40px;
    position: relative;
    color: #fff;
    transition: all 0.3s ease 0s;
}
.footer-social .icon-pentagon svg {
    font-size: 16px;
}
ul.unstyled {
    list-style: none;
    margin: 0;
    padding: 0;
}
.copyright-info {
    font-size: 16px;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    max-width: 850px;
    margin: 30px auto 0;
    padding-top: 15px;
}
.copyright-info a {
    color: var(--theme-color);
    text-decoration: none;
}
.fixed-div{
    background: #191919;
    box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.2);
}
.services2{
    padding: 80px 0;
}
.testimonial .slick-dots li{
    width: unset !important;
    height: unset !important;
    margin: unset;
}
.testimonial .slick-dots li button{
    width: 28px !important;
    height: 8px !important;
    border: 1px solid #fff !important;
    opacity: 0.7 !important;
    padding: 0 !important;
    margin: 5px 7px !important;
}
.testimonial .slick-dots li button::before{
    display: none;
}
.testimonial .slick-dots{
    bottom: -30px !important;
    left: 50%;
    transform: translateX(-50%);
}
.testimonial .slick-dots li.slick-active button{
    background-color: #fff;
    opacity: 1;
}
.title-icon:hover{
    color: var(--theme-color);
}
.team-content{
    margin-top: 5px;
}

































































































































/***********************************portfolio******************************  */
.portfolio{
    background-color: var(--theme-inner-bg);
    padding: 80px 0;
    position: relative;
    padding-bottom: 0;
}
.heading {
    padding-bottom: 50px;
    margin-top: 10px;
    transition: all 0.3s ease 0s;
}
.title-icon.classic {
    font-size: 32px;
    margin-right: 15px;
    margin-top: 0px;
    color: #959595;
}
.title.classic {
    position: relative;
    margin: 0;
    line-height: 34px;
    border-left: 2px solid #e7e7e7;
    display: inline-block;
    padding-left: 15px;
    transition: all 0.3s ease 0s;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #323232;
    font-size: 32px;
    transform: translateY(5px);
}
/***********************************portfolio******************************  */

/********************************* Form ***********************************/
.green-text{
    color:var(--theme-color);
    font-family: "Lato-Medium";
}
.breadcrumb-item a{
    color:var(--theme-color);
}
.banner-area{
    position: relative;
}
.banner-title-content{
    position: absolute;
    top: 50%;
    width: 100%;
    height: 100%;
    color:var(--light-bg)
}
.banner-area img{
    height: 160px;
    object-fit: cover;
    width: 100%;
}
.form-wrapper{
    width: 50rem;
    margin: auto;
    padding: 2rem 0px;
    z-index: 1;
    position: relative;

}
.custom-checkbox {
    display: inline-block;
  }
  /* Hide the default checkbox */
  .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  /* Style the custom checkbox container */
  .custom-checkbox label {
    position: relative;
    display: inline-block;
    padding-left: 30px; /* Adjust as needed */
    line-height: 24px; /* Adjust as needed */
    cursor: pointer;
  }
  /* Style the checkmark */
  .custom-checkbox .checkmark {
    position: absolute;
    border-radius: 5px;
    top: 0;
    left: 0;
    height: 20px; /* Adjust as needed */
    width: 20px; /* Adjust as needed */
    background-color: #fff; /* Background color when unchecked */
    border: 1px solid var(--theme-color); /* Border color */
  }
  /* Style the checkmark when checked */
  .custom-checkbox input:checked + .checkmark {
    background-color: var(--theme-color); /* Background color when checked */
    border: 2px solid var(--theme-color); /* Border color when checked */
  }
  /* Style the checkmark shape (can be customized) */
  .custom-checkbox .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  /* Show the checkmark when checked */
  .custom-checkbox input:checked + .checkmark:after {
    display: block;
  }
  /* Style the checkmark shape (can be customized) */
  .custom-checkbox .checkmark:after {
    left: 5px;
    top: 0px;
    width: 7px; /* Adjust as needed */
    height: 13px; /* Adjust as needed */
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  .btn-main,.btn-main:hover{
    background: var(--theme-color);
    font-family: lato-bold;
    color:var(--light-bg);
  }
  .main-head-form{
    font-size: 30px;
    text-align: center;
    font-family: "Lato-Medium";
    color: var(--dark-text);
    background: #f5f5f5;
    padding: 10px 0px;
    border-radius: 5px;
    box-shadow: 17px 0px 3px -1px #f5f5f5;
  }
  .form-check-input:checked{
    background-color: var(--theme-color);
    border-color: var(--theme-color);
  }
  .btn-cancel,.btn-cancel:hover{
    background: var(--main-text-color);
    color:var(--light-bg);
  }
/********************************* Form ***********************************/
/********************************* Listing Page ***********************************/
.add-user{
    background: var(--button-bg);
    float: right;
    color: var(--light-bg);
    padding: 6px 10px;
    border-radius: 5px;
}
.add-user:hover{
    color: var(--light-bg);
}
.table-responsive {
    margin: 30px 0;
}
.table-wrapper {
      border-radius: 3px;
      /* width:1000px; */
      border: 1px solid #ebebeb;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.table-title {
    padding-bottom: 15px;
    background: var(--grey);
    color: var(--light-bg);
    padding: 10px 15px;
    border-radius: 3px 3px 0 0;
}
.table-title h2 {
    margin: 8px 0 0;
    font-size: 22px;
}
.table-title .btn {
    color: var(--dark-text);
    float: right;
    font-size: 13px;
    background: var(--light-bg);
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    margin-left: 10px;
}
.table-title .btn:hover, .table-title .btn:focus {
    background: var(--theme-inner-bg);
}
.table>:not(:last-child)>:last-child>*{
    border-bottom: none;
}
.table-title .btn i {
    float: left;
    font-size: 21px;
    margin-right: 5px;
}
.table-title .btn span {
    float: left;
    margin-top: 2px;
}
table.table tr th, table.table tr td {
    /* border-color: #e9e9e9; */
    padding: 12px 15px;
    font-size: 14px;
    vertical-align: middle;
}
table.table tr th:first-child {
    width: 60px;
}
table.table tr th:last-child {
    width: 100px;
}
table.table-striped tbody tr:nth-of-type(odd) {
    background-color: var(--form-bg)
}
/* table.table-striped.table-hover tbody tr:hover {
    background: var(--light-bg);
} */
table.table th i {
    font-size: 13px;
    margin: 0 5px;
    cursor: pointer;
}	
table.table td:last-child i {
    opacity: 0.9;
    font-size: 22px;
    margin: 0 5px;
}
table.table td a {
    font-weight: bold;
    color: var(--table-btn);
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
}

table.table td a.delete {
    color: var(--text-danger);
}
table.table td i {
    font-size: 19px;
}
table.table .avatar {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
}
.table-left-btn{
    color:var(--dark-text);
    padding: 0px 10px;
    cursor: pointer;
    font-size: 13px;
}
.status-table{
    display: flex;
    align-items: center;
}
.status {
    font-size: 30px;
    margin: -1px 2px 0 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 10px;
}

.pagination {
    float: right;
    margin: 0 0 5px;
}
.pagination li a {
    border: none;
    font-size: 13px;
    min-width: 30px;
    min-height: 30px;
    color: var(--text-dark);
    text-decoration: none;
    margin: 0 2px;
    line-height: 30px;
    border-radius: 2px ;
    text-align: center;
    padding: 3px 10px;
}
.pagination li.active a, .pagination li.active a.page-link {
    background: var(--theme-color);
    color:var(--grey)
}
.pagination li.active a:hover {        
    background: var(--theme-color);
}
.pagination li.disabled i {
    color: #ccc;
}
.pagination li i {
    font-size: 16px;
    padding-top: 6px
}
.hint-text {
    float: left;
    margin-top: 10px;
    font-size: 13px;
}
/********************************* Listing Page ***********************************/

/********************************* Login ***********************************/
section#formHolder {
    padding: 50px 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

/* Brand Area */
.brand {
    padding: 20px;
    background: url(../images/login_bg01.jpg);
    background-size: cover;
    background-position: bottom;
    color: #fff;
    min-height: 540px;
    position: relative;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.6s cubic-bezier(1.000, -0.375, 0.285, 0.995);
    z-index: 9999;
}

.brand.active {
    width: 100%;
}

.brand::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.85);
    z-index: -1;
}

.brand a.logo {
    color: var(--theme-color);
    font-size: 20px;
    font-weight: 700;
    text-decoration: none;
    line-height: 1em;
}

.brand a.logo span {
    font-size: 30px;
    color: #fff;
    transform: translateX(-5px);
    display: inline-block;
}

.brand .heading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    transition: all 0.6s;
}

.brand .heading.active {
    top: 100px;
    left: 100px;
    transform: translate(0);
}

.brand .heading h2 {
    font-size: 70px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
}

.brand .heading p {
    font-size: 15px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2px;
    white-space: 4px;
}

.brand .success-msg {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 60px;
}

.brand .success-msg p {
    font-size: 25px;
    font-weight: 400;
}

.brand .success-msg a {
    font-size: 12px;
    text-transform: uppercase;
    padding: 8px 30px;
    background: var(--theme-color);
    text-decoration: none;
    color: #fff;
    border-radius: 30px;
}

.brand .success-msg p,
.brand .success-msg a {
    transition: all 0.9s;
    transform: translateY(20px);
    opacity: 0;
}

.brand .success-msg p.active,
.brand .success-msg a.active {
    transform: translateY(0);
    opacity: 1;
}

/* Form Area */
.form-login {
    position: relative;
}

.form-login .form-peice {
    min-height: 510px;
    margin-top: 30px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);   
    padding: 30px 0 60px;
    transition: all 0.9s cubic-bezier(1.000, -0.375, 0.285, 0.995);
    position: absolute;
    top: 0;
    left: -30%;
    width: 130%;
    overflow: hidden;
}

.form-login .form-peice.switched {
    transform: translateX(-100%);
    width: 100%;
    left: 0;
}

.form-login form {
    padding: 0 40px;
    margin: 0;
    width: 70%;
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
}

.form-login .form-group {
    margin-bottom: 5px;
    position: relative;
}form-login

.form-login .form-group.hasError input {
    border-color: var(--theme-color);
}

.form-login label {
    font-size: 13px;
    font-weight: 400;
    color:var(--slick-bg);
    text-transform: uppercase;
    transform: translateY(40px);
    transition: all 0.4s;
    cursor: text;
    z-index: -1;
}

.form-login label.active {
    transform: translateY(10px);
    font-size: 10px;
}

.form-login label.fontSwitch {
    font-family: 'Raleway', sans-serif !important;
    font-weight: 600;
}

.form-login input:not([type=submit]) {
    background: none;
    outline: none;
    border: none;
    display: block;
    padding: 10px 10px;
    width: 100%;
    border-bottom: 1px solid #eee;
    color: #444;
    font-size: 15px;
    z-index: 1;
}
#root > div,#formHolder{
    height: 100%;
}
.form-login input:not([type=submit]).hasError {
    border-color: var(--theme-color);
}

.form-login span.error {
    color: var(--theme-color);
    font-size: 12px;
    position: absolute;
    bottom: -20px;
    right: 0;
    display: none;
}

.form-login input[type=password] {
    color: var(--theme-color);
}

.form-login .CTA {
    margin-top: 30px;
}

.form-login .CTA input {
    font-size: 12px;
    text-transform: uppercase;
    padding: 10px 30px;
    background: var(--theme-color);
    color: var(--light-bg);
    border-radius: 30px;
    margin-right: 20px;
    border: none;
}

.form-login .CTA a.switch {
    font-size: 13px;
    color: var(--slick-bg);
    text-decoration: underline;
    transition: all 0.3s;
}

.form-login .CTA a.switch:hover {
    color: var(--theme-color);
}
.bar1, .bar2, .bar3 {
    width: 22px;
    height: 2px;
    background-color: #fff;
    margin: 4px 0;
    transition: 0.4s;
  }
  .bar-icon.menu-change .bar2{
    opacity: 0;
  }
  .menu-change .bar1 {
    transform: translate(0px, 7px) rotate(-45deg);
}

.menu-change .bar3 {
    transform: translate(0px, -9px) rotate(45deg)
}
.navbar-toggler{
    background: var(--theme-color);
    border-radius: 0;
    color: #fff !important;
    padding: 0.25rem 0.75rem;
    line-height: 1;
    z-index: 999;
    border-color: transparent !important;
    box-shadow: none !important;
}
.login-btn{
    border: 1px solid var(--theme-color) !important;
    border-radius: 50px !important;
}
.login-btn:hover{
    background-color: var(--theme-color);
    color: #fff !important;
}


.fade-scale.in {
    opacity: 1;
    transform: scale(1);
    transition: 5s;
    background: rgb(8 7 7 / 69%);
}
.fade-scale {
    opacity: 0;
    transform: scale(0);
    transition: 5s;
}

/* modal */
.btn-save{
    background: var(--light-bg);
    border:none;
    padding: 2px 10px;
    border: 1px solid var(--slick-bg);
    border-radius: 17px;
}
.modal-boxes{
    border: 1px solid var(--grey);
    padding: 5px;
    cursor:pointer;
}
.modal-boxes.active{
    border:2px solid var(--slick-bg)
}
.modal-main-head{
    font-size: 23px;
    font-family: "Lato-Medium";
    color:var(--slick-bg)
}
.spinner-border{
    width: 1rem;
    height: 1rem;
    margin-left: 10px;
    border: 0.15em solid var(--dark-text);
    border-right-color: transparent;
}
.form-select2{
    padding: 3px 3px;
    border: 1px solid #ccc;
    border-radius: 3px;
}
.modal-subhead{
    background: #f7f7f7;
    padding: 1px 0px;
    margin-bottom: 15px;
}
.loader {
    animation: r 2s linear infinite;
    transform-origin: 48% 47%;
  }
  
  @keyframes r {
    to {
      transform: rotate(-360deg)
    }
  }


  .xilliContent{
    margin-top: 65px;
    background-color: #fff;
   }

   @media (min-width: 768px) {
    .gradient-form {
      height: 100vh !important;
    }
    .xilliContent {
      padding: 0px 15px;
  }
  }

  .loginlink{
    color: #007bb6;
    cursor: pointer;
  }

  .showPswd{
    position: absolute;
      right: 25px;
      font-size: 20px !important;
      top: 30px;
      cursor: pointer;
  }
  .logo-seffo{
    font-size: 26px;
    color: #fff;
    font-family: "Lato-Bold";
  }