@media(max-width:991px){
    .team {
        padding-bottom: 50px;
    }
    .form-login form{
        width: 80%;
    }
    .menu-collapse.collapse:not(.show){
        display: block;
        position: fixed;
        top: 0;
        left: -100%;
        background: rgb(0 0 0 / 90%);
        width: 100%;
        height: 100%;
        padding: 60px 20px;
        z-index: 99;
        transition: .5s;
        overflow: auto;
    }
    .menu-collapse.menuOpen{
        left: 0 !important;
    }
    .menu-collapse .nav-pills{
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }
    .dropdown-submenu{
        position: unset;
        min-width: unset;
        visibility: visible;
        opacity: 1;
        transform: unset;
        /* display: none; */
        max-height: 0;
        visibility: hidden;
        opacity: 0;
        transition: .5s;
    }
    .dropdown-parent:hover .dropdown-submenu{
        /* display: block; */
        max-height: 5000px;
        visibility: visible;
        opacity: 1;
        transition: .5s;
    }
    /* .heading{
        padding-bottom: 30px;
    } */
    .services-sec{
        padding-bottom: 30px;
    }
    .heading{
        padding-bottom: 30px;
    }
    .facts-num .counter{
        font-size: 34px;
    }
    .services2,.portfolio,.services{
        padding: 50px 0;
    }
    .title{
        font-size: 28px;
    }
    .about-img-col{
        width: 100%;
    }
    .main-head{
        font-size: 44px;
    }
    .sub-text{
        font-size: 22px;
    }
    .table-wrapper{
        border: 0;
    }
    .btn{
        padding: 6px 12px;
        font-size: 12px !important;
    }
}
@media(max-width:767px){
    .banner-title-content {
        top: 25%;
    }
    .form-wrapper {
        width: 100%;
    }
    .img-block-head h2{
        font-size: 16px;
        margin-bottom: 10px;
    }
    .img-block-head h3 {
        font-size: 28px;
    }
    .img-block-right {
        padding: 40px;
    }
    .main-head{
        font-size: 30px;
    }
    .services-sec{
        flex-direction: column;
    }
    .title-icon{
        text-align: center;
        margin-right: 0;
        text-align: center;
        margin: 0 0 20px;
    }
    .title {
        font-size: 24px !important;
        border-left: 0;
        text-align: center;
    }
    .client_logo{
        display: flex;
        margin: auto !important;
        align-items: center;
        justify-content: center;
    }
    .parallax,.our-team,.pricing,.clients,.services2,.portfolio,.footer {
        padding: 60px 0;
    }
    section#formHolder {
        padding: 0;
    }

    div.brand {
            width: 100%;
            min-height: 300px;
    }
    .brand .heading h2 {
        font-size: 23px;
    }
    .brand .heading p{
        font-size:11px;
    }
    div.brand.active {
        min-height: 100vh ;
    }

    div.brand .heading.active {
        top: 200px;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    div.brand .success-msg p {
        font-size: 16px;
    }

    div.brand .success-msg a {
        padding: 5px 30px;
        font-size: 10px;
    }

    .form-login {
        width: 100%;
        min-height: 500px;
        margin-left: 0vw;
    }

    .form-login .form-peice {
        margin: 0;
        top: 0;
        left: 0;
        width: 100% !important;
        transition: all .5s ease-in-out;
    }

    .form-login .form-peice.switched {
        transform: translateY(-100%);
        width: 100%;
        left: 0;
    }

    .form-login .form-peice > form {
        width: 100% ;
        padding: 20px;
        left: 50%;
    }
    .main-head {
        font-size: 28px;
    }
    .sub-text {
        font-size: 20px;
    }
    .banner-form{
        height: 700px;
    }
    .navbar-brand img{
        max-width: 80%;
    }
    .react-slick-dots svg{
        width: 20px;
        height: 20px;
    }
    .react-slick-dots p{
        font-size: 12px;
        margin-bottom: 0;
    }
    .main-wrapper + div{
        margin-top: 0;
    }
    .facts-num .counter{
        font-size: 24px;
        font-weight: unset;
    }
    .parallax h2{
        font-size: 24px;
    }
    .plan-price{
        font-size: 32px;
        padding: 20px 0;
    }
    .plan {
        margin-bottom: 40px;
    }
    .plan-name{
        font-size: 22px;
    }
    .col-md-6.col-lg-3:last-child .team{
        padding-bottom: 0;
    }
    .widget-title{
        font-size: 18px;
        margin-bottom: 15px;
    }
    .gap-30{
        height: 15px;
    }
    .image-block-content .feature-icon{
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .image-block-content .feature-icon svg{
        width: 22px;
        height: 22px;
    }
    .image-block-content:last-child{
        margin-bottom: 0;
    }
    .feature-content {
        width: calc(100% - 75px);
    }
    .services{
        padding-top: 0;
    }
    .table-title h2{
        margin: 0;
        margin-bottom: 10px;
        font-size: 18px;
    }

}
